import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {getClientsList, getSingleClient, removeSingleClient} from "@actions/clientsActions";
import {Input, Table, Tooltip} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import TableLoader from "@components/Loaders/tableLoader";
import {getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import ClientControlPopup from "@components/ClientControlPopup/ClientControlPopup";
import {SearchOutlined} from "@ant-design/icons";



function Clients({loading, intl, getClientsList, clientsList, client, getSingleClient, removeSingleClient}) {
    let history = useHistory();
    const search = history && history.location && history.location.search

    const [totalPages, setTotalPages] = useState(0);
    const [limitPage, setLimitPage] = useState(+getUrlValue(search).limitPage || 10);
    const [currentPage, setCurrentPage] = useState(+getUrlValue(search).currentPage || 1);
    const [searchValue, setSearchValue] = useState(getUrlValue(search).searchValue || "")
    const [sortDirDesc, setSortDirDesc] = useState( getUrlValue(search).sortDirDesc || "")
    const [tableData, setTableData] = useState([]);
    const [showModalContent, setShowModalContent] = useState(false);

    const stor = useRef({});


    useEffect(() => {
        stor.current.searchValue =  getUrlValue(search).searchValue || "";
        stor.current.currentPage = +getUrlValue(search).currentPage || 1;
        stor.current.limitPage = +getUrlValue(search).limitPage || 10;
        stor.current.sortDirDesc = getUrlValue(search).sortDirDesc === "desc";
        stor.current.isPopEvent = false;
        stor.current.beforeSearchPage = null;
        stor.current.beforeSearchLimit = null;
    }, [])


    const pushHistpry = (currentPage, limitPage, searchValue, sortDirDesc, isFirst, getSingleClient) => {
        const {params} =  getUrlString({currentPage, limitPage, searchValue, sortDirDesc: sortDirDesc? "desc": ''});
        isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()});
    }

    useEffect(() => {
        if (!stor.current.isPopEvent) {

            let p = currentPage, l = limitPage, isEqual = true;

            if (searchValue && searchValue.length > 0) {
                if (!(stor.current.beforeSearchPage && stor.current.beforeSearchLimit)) {
                    stor.current.beforeSearchPage = currentPage;
                    stor.current.beforeSearchLimit = limitPage;
                    p = 1;
                }
                if (stor.current.searchValue !== searchValue) {
                    p = 1
                }
            } else {
                p = stor.current.beforeSearchPage || currentPage;
                l = stor.current.beforeSearchLimit || limitPage;
                stor.current.beforeSearchPage = null;
                stor.current.beforeSearchLimit = null;
            }


            if (p != currentPage || l != limitPage) {
                isEqual = false;
            }

            setCurrentPage(+p);
            setLimitPage(+l);
            if (!isEqual) {
                return;
            }

            if (typeof stor.current.currentPage != 'undefined' && stor.current.currentPage != currentPage ||
                typeof stor.current.limitPage != 'undefined' && stor.current.limitPage != limitPage ||
                (typeof stor.current.searchValue != 'undefined' && stor.current.searchValue != searchValue) ||
                (typeof stor.current.sortDirDesc != 'undefined' && stor.current.sortDirDesc != sortDirDesc)) {
                pushHistpry(currentPage, limitPage, searchValue, sortDirDesc)

            } else {
                pushHistpry(currentPage, limitPage, searchValue || '', sortDirDesc, true)
            }
        }

        return () => {
            stor.current.searchValue = searchValue;
            stor.current.currentPage = currentPage;
            stor.current.limitPage = limitPage;
            stor.current.sortDirDesc = sortDirDesc;
        }
    }, [searchValue, currentPage, limitPage, sortDirDesc])


    const getRequestBody = (loc) => {
        return getUrlValue( loc || search);
    }

    const fetchClients = (isFirst) => {
        let initialForm = getRequestBody()
        initialForm = {
            ...initialForm,
            currentPage : initialForm.currentPage || currentPage,
            limitPage: initialForm.limitPage || limitPage,
            searchValue: initialForm.searchValue || searchValue,
        }
        if(Object.keys(initialForm).length) {
            getClientsList(initialForm.currentPage, initialForm.limitPage, initialForm.searchValue, initialForm.sortDirDesc !=="desc" ? false : initialForm.sortDirDesc ==="desc" );
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        fetchClients(true);
    }, [])


    useEffect(() => {
        if (clientsList && clientsList.items) {
            setTableData(clientsList.items)
            setTotalPages(clientsList.total)
        }
    }, [clientsList]);

    const columns = [
        {
            title: intl.formatMessage({id: "name"}),
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
            sortOrder: sortDirDesc? "descend" : "ascend",
            sortDirections: ["descend","ascend"],
            showSorterTooltip: false,
            sorter: true,
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        stor.current.isPopEvent = false;
                        setSortDirDesc(prev => !prev)
                    }
                };
            },
        },
        {
            title: intl.formatMessage({id: "secret"}),
            dataIndex: 'secret',
        },
        {
            title: intl.formatMessage({id: "actions"}),
            dataIndex: 'actions',
            align: 'right',
            className: "noPadding",
            render: (text, record) => {
                return (<div className="iconBox">
                    <Tooltip title={<FormattedMessage id='edit'/>} color={"#fff"} placement="bottom">
                        <div className="edit-icon icon" onClick={() => {
                            getSingleClient(record.id)
                        }}/>
                    </Tooltip>

                </div>);
            },
        },
    ];

    const changeSearch = (e) => {
        stor.current.isPopEvent = false;
        setSortDirDesc(false)
        setSearchValue(e.target.value)
    }

    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search;
            const {sortDirDesc, currentPage, limitPage, searchValue} = getUrlValue(loc);
            if (loc) {
                if (action === 'POP') {
                    stor.current.isPopEvent = true;
                    getClientsList(currentPage, limitPage, searchValue, sortDirDesc == "desc");
                } else if (action === 'PUSH') {
                    getClientsList(currentPage, limitPage, searchValue, sortDirDesc == "desc");
                }
                setSearchValue(searchValue || "");
                setCurrentPage(+currentPage);
                setLimitPage(+limitPage);
                setSortDirDesc(sortDirDesc == "desc");
            }
        })
    },[history])

    useEffect(() => {
        if (client && client.id) {
            setShowModalContent(true)
        }
    }, [client])

    return (
        <div className="content clients">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='clients'/></div>
                <div className="titleArea--BtnGroup">
                    <button className="mainBtn" onClick={() => {setShowModalContent(true)}}>
                        <div className="icon"/>
                        <FormattedMessage id='add_client'/>
                    </button>
                </div>
            </div>
            <div className="tableSearch">
                <Input suffix={<SearchOutlined/>}
                       autoComplete="off"
                       onChange={(e) => changeSearch(e)}
                       value={searchValue}
                />
            </div>
            <div className="mainTable clients">
                {
                    loading._client_list ? <TableLoader count={limitPage} column={2} actions={1} drag={false} icon={false}/> :
                        <Table
                            dataSource={tableData}
                            columns={columns}
                            rowKey="id"
                            scroll={{x: '100%'}}
                            pagination={
                                {
                                    showSizeChanger: true,
                                    defaultCurrent: currentPage,
                                    defaultPageSize: limitPage,
                                    showLessItems: true,
                                    onChange: (page, limit) => {
                                        stor.current.isPopEvent = true;
                                        setLimitPage(l => {
                                            stor.current.isPopEvent = false;
                                            if (l === limit) {
                                                setCurrentPage(page);
                                            } else {
                                                setCurrentPage(1);
                                            }
                                            return limit;
                                        });


                                    },
                                    total: totalPages,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`
                                }
                            }
                        />
                }

            </div>
            {showModalContent ? <ClientControlPopup
                handleCancel={() => {
                    removeSingleClient();
                    setShowModalContent(false)}
                }
                show={showModalContent}
                client={client}/> : "" }

        </div>
    )
}

function mapStateToProps(state) {
    return {
        clientsList: state.clientsList,
        loading: state.loading,
        client: state.singleClient
    }
}

const mapDispatchToProps = {
    getClientsList,
    getSingleClient,
    removeSingleClient
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Clients));