import {call, put, delay, take, fork, cancel} from "redux-saga/effects";
import {onError, safe} from "./handlerError";
import {GetResource, PutResource} from "../../utils/HttpUtil";
import {getQuery} from "../../utils/CommonUtils";
import {
    REQUEST_TRANSACTIONS_LIST,
    SET_TRANSACTIONS_LIST,
    REQUEST_TRANSACTIONS_FILTER,
    SET_TRANSACTIONS_FILTER,
    REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
    SET_TRANSACTIONS_CSV_DOWNLOADER_URL,
    REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD,
    SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD,
    SET_ALLOW_CSV_CANCEL
} from "@actions/actionTypes";
import {getFormattedBody} from "@services/TableServices";
import {waitForFile} from "@services/CsvDownloadService";
import "./csvProcess";
let downloadTask

const takeLatest = (patternOrChannel, saga, ...args) => fork(function*() {
    while (true) {
        const action = yield take(patternOrChannel)
        if (patternOrChannel === REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL) {
            downloadTask = yield fork(saga, ...args.concat(action))
        } else {
            yield fork(saga, ...args.concat(action))
        }

    }
})

function* fetchTransactionsList({params}) {
    let query = getQuery(params);
    const transaction = yield call(GetResource, `/transactions/stats?${query}`);
    yield put({type: SET_TRANSACTIONS_LIST, transaction})
}

function* fetchTransactionsFilter({params}) {
    const filter = yield call(GetResource, `/transactions/statsfilter`);
    yield put({type: SET_TRANSACTIONS_FILTER, filter})
}

function* fetchTransactionsCsvDownloaderUrl({data, ...restProps}) {
    const checkProcessUrl = "/transactions/getreporturl";
    let id = restProps.processId;
    if (!id) {
        const formattedData = getFormattedBody(data)
        const query = getQuery(formattedData)
        id = yield call(GetResource, `/transactions/generatereport?${query}`);
    }
    window.CsvProcess.setProcess({
        actionType: REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
        processId: id,
        checkProcessUrl
    })
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowTransaction: true}})
    const link = yield waitForFile(REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL, id, checkProcessUrl);
    yield put({type: SET_TRANSACTIONS_CSV_DOWNLOADER_URL, link})
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowTransaction: false}})
    window.CsvProcess.removeProcess(id);
}
function* cancelTransactionCsvDownload(processId) {
    window.CsvProcess.setCanceled(processId);
    if (processId) {
        yield call(PutResource, `/transactions/cancelreport?key=${processId}`)
    }
    yield delay(2000)
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowTransaction: false}})
    yield put({type: SET_TRANSACTIONS_CSV_DOWNLOADER_URL, link: ""});
    yield put({type: SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD})
    window.CsvProcess.removeProcess(processId);
}

function* cancelFetching() {
    yield cancel(downloadTask);
    const processId = window.CsvProcess.getProcessIdByAction(REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL)
    yield cancelTransactionCsvDownload(processId)
}

function* transactionsSaga() {
    yield takeLatest(REQUEST_TRANSACTIONS_LIST, safe(onError, fetchTransactionsList))
    yield takeLatest(REQUEST_TRANSACTIONS_FILTER, safe(onError, fetchTransactionsFilter))
    yield takeLatest(REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL, safe(onError, fetchTransactionsCsvDownloaderUrl))
    yield takeLatest(REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD, safe(onError, cancelFetching))
}

export default transactionsSaga