import React, {useEffect, useState} from 'react';
import { Menu } from 'antd';

import { Link,  useLocation, useHistory } from "react-router-dom";
import {FormattedMessage} from "react-intl";
import "./style.sass";
import activeBetsIcon from "@assets/img/icon/active_bets.svg";
import clientsIcon from "@assets/img/icon/clients.svg";
import betsIcon from "@assets/img/icon/bets.svg";
import transactionsIcon from "@assets/img/icon/transactions.svg";

function LeftBare() {
    const localHistory = useHistory();
    let location = useLocation();
    const [path, setPath] = useState(localHistory.location.pathname)

    const getPathParams = (path) => {
        let currentPath = location.pathname;
        let params = '';
        if (currentPath.indexOf(path) !== -1) {
            params = location.search;
        }
        return path + params;
    }

    useEffect(() => {
        setPath(location.pathname)
    },[location])

    return (
        <Menu  mode="inline" selectedKeys={[path]} className='left-bar-block'>
            <Menu.Item key="/active_bets" icon={<img src={activeBetsIcon}/>}>
                <Link  to={getPathParams("/active_bets")}><FormattedMessage id='active_bets' /></Link >
            </Menu.Item>
            <Menu.Item key="/bets" icon={<img src={betsIcon}/>}>
                <Link  to={getPathParams("/bets")}><FormattedMessage id='bets' /></Link >
            </Menu.Item>
            <Menu.Item key="/transactions" icon={<img src={transactionsIcon}/>}>
                <Link  to={getPathParams("/transactions")}><FormattedMessage id='transactions' /></Link >
            </Menu.Item>
            <Menu.Item key="/clients" icon={<img src={clientsIcon}/>}>
                <Link  to={getPathParams("/clients")}><FormattedMessage id='clients' /></Link >
            </Menu.Item>
            {/* <Menu.Item key="categories" icon={<i className="fi fi-sr-apps"></i>}>
                <Link to="/categories"><FormattedMessage id='categories' /></Link>
            </Menu.Item>
            <Menu.Item key="participants" icon={<i className="fi fi-sr-users"></i>}>
                <Link to="/participants"><FormattedMessage id='participants' /></Link>
            </Menu.Item>
            <Menu.Item key="markets" icon={<i className="fi fi-sr-shopping-cart"></i>}>
                <Link to="/markets"><FormattedMessage id='markets' /></Link>
            </Menu.Item>
            
            <SubMenu key="events" title={<FormattedMessage id='events' />} icon={<i className="fi fi-sr-calendar"></i>}>
                <Menu.Item key="available">
                    <Link to="/available">
                        <FormattedMessage id='available' />
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ordered">
                    <Link to="/ordered">
                        <FormattedMessage id='ordered' />
                    </Link>
                </Menu.Item>
                <Menu.Item key="history">
                    <Link to="/history">
                        <FormattedMessage id='history' />
                    </Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="subscription" title={<FormattedMessage id='subscription' />} icon={<i className="fi fi-sr-dollar" ></i>}>
                <Menu.Item key="prematch_leagues">
                    <Link to="/prematch_leagues">
                        <FormattedMessage id='prematch_leagues' />
                    </Link>
                </Menu.Item>
                <Menu.Item key="in_play_leagues">
                    <Link to="/in_play_leagues">
                        <FormattedMessage id='in_play_leagues' />
                    </Link>
                </Menu.Item>
            </SubMenu>
            <Menu.Item key="users" icon={<i className="fi fi-sr-user" ></i>}>
                <Link to="/users">
                    <FormattedMessage id='users' />
                </Link>
            </Menu.Item>
            <Menu.Item key="logs" icon={<i className="fi fi-sr-time-quarter-to"></i>}>
                <Link to="/logs">
                    <FormattedMessage id='logs' />
                </Link>
            </Menu.Item> */}
        </Menu>
    )
}

export default LeftBare;